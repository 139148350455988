import { useEffect } from 'react'

const useGetDocumentTitlePrice = () => {


  
    document.title = `CroshiSwap`
  
}
export default useGetDocumentTitlePrice
